<template>
  <div class="income-expense-container">
    <b-row>
      <b-col
        sm="12"
      >
        <b-card class="card-app-design h-100">
          <b-card-title class="d-flex justify-content-center">
            <span class="text-info">
              Income Vs Expense for the month of {{ currentMonthYear }}
            </span>
          </b-card-title>
          <b-row>
            <b-col
              sm="12"
              md="3"
              class="mt-5 pt-2"
            >
              <vue-apex-charts
                type="donut"
                height="350"
                :options="apexChatData.chartOptions"
                :series="apexChatData.series"
              />
            </b-col>

            <b-col
              sm="12"
              md="9"
            >
              <b-card>
                <b-card-title>Income for the month of {{ currentMonthYear }}</b-card-title>
                <vue-apex-charts
                  type="bar"
                  :options="incomeBarChartOptions"
                  :series="incomeBarChartseries"
                />
              </b-card>
            </b-col>
            <b-col
              sm="12"
            >
              <b-card>
                <b-card-title>Expenses for the month of {{ currentMonthYear }}</b-card-title>
                <vue-apex-charts
                  type="bar"
                  :options="expensesBarChartOptions"
                  :series="expensesBarChartseries"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { getTodayMonthAndYear, getFirstAndLastDayOfMonth } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { supabase } from '@/libs/supabaseClient'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BRow,
    BCol,
  },

  data() {
    return {
      currentMonthYear: '',
      apexChatData: {
        series: [85, 16],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#389f4f',
            '#da3d3d',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                    color: '#4fda6e',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Income',
                    colors: ['#FF0000'],
                    formatter() {
                      return '85%'
                    },
                  },
                },
              },
            },
          },
          labels: ['Income', 'Expenses'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },

      },
      incomeBarChartOptions: {
        chart: {
          id: 'vuechart-example',
        },
        colors: ['#9acba5'],
        yaxis: {
          title: {
            text: 'Amount',
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Days',
          },
        },
      },
      incomeBarChartseries: [{
        name: 'Farm Income',
        data: [],
      }],
      expensesBarChartOptions: {
        chart: {
          id: 'expenses-chart',
        },
        colors: ['#eaa8a8'], // Set the colors for individual bars IUC8057778364
        yaxis: {
          title: {
            text: 'Amount',
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Days',
          },
        },
      },
      expensesBarChartseries: [{
        name: 'Farm Expenses',
        data: [],
      }],
      currentMonthDates: [],
    }
  },

  created() {
    this.currentMonthYear = getTodayMonthAndYear()
    this.currentMonthDates = this.getDatesInCurrentMonth()
    this.handleGetProduceAggregatedData()
    console.log('::debug month dates:', this.currentMonthDates)
  },

  methods: {
    getDatesInCurrentMonth() {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth()

      // Set the date to the first day of the current month
      // const firstDayOfMonth = new Date(currentYear, currentMonth, 1)

      // Get the last day of the current month
      const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

      // Generate an array of dates from 1 to the last day of the month
      const datesArray = Array.from({ length: lastDayOfMonth }, (_, index) => index + 1)

      return datesArray
    },
    getIncomeComputations(incomeData) {
      const totalIncomeAmount = incomeData.map(data => data.amount)
        .reduce((acc, amount) => acc + amount, 0)

      // Initialize the result array with zeros
      const incomeTransactionsDateAmountData = Array.from({ length: this.currentMonthDates.length }, () => 0)

      // Map the transactions to the incomeTransactionsDateAmountData
      incomeData.forEach(transaction => {
        const transactionDate = new Date(transaction.transaction_date).getDate()
        const index = this.currentMonthDates.filter(item => item === transactionDate)

        // If the date is found in the currentDateArray, update the incomeTransactionsDateAmountData with the total amount
        incomeTransactionsDateAmountData[index - 1] += transaction.amount
      })

      return {
        totalIncomeAmount,
        incomeTransactionsDateAmountData,
      }
    },
    getExpensesComputations(incomeData) {
      const expensesTotalAmount = incomeData.map(data => data.amount)
        .reduce((acc, amount) => acc + amount, 0)

      // Initialize the result array with zeros
      const expensesTransactionsDateAmountData = Array.from({ length: this.currentMonthDates.length }, () => 0)

      // Map the transactions to the expensesTransactionsDateAmountData
      incomeData.forEach(transaction => {
        const transactionDate = new Date(transaction.transaction_date).getDate()
        const index = this.currentMonthDates.filter(date => date === transactionDate)

        // If the date is found in the currentDateArray, update the expensesTransactionsDateAmountData with the total amount
        expensesTransactionsDateAmountData[index - 1] += transaction.amount
      })

      return {
        expensesTotalAmount,
        expensesTransactionsDateAmountData,
      }
    },
    async handleGetProduceAggregatedData() {
      const { firstDate, lastDate } = getFirstAndLastDayOfMonth()
      // eslint-disable-next-line no-useless-concat
      const firstMonthDate = (new Date(`${firstDate} ` + '00:01').getTime() / 1000)
      // eslint-disable-next-line no-useless-concat
      const lastMonthDate = (new Date(`${lastDate} ` + '23:59').getTime() / 1000)
      console.log('::debug firstMonthDate:', firstMonthDate)
      console.log('::debug lastMonthDate:', lastMonthDate)
      this.$Progress.start()
      try {
        const { data: expenseResponse, error } = await supabase
          .from('expense')
          .select('*')
          .gte('transaction_date_time', firstMonthDate)
          .lte('transaction_date_time', lastMonthDate)
        const { data: incomeResponse, IncomeError } = await supabase
          .from('income')
          .select('*')
          .gte('transaction_date_time', firstMonthDate)
          .lte('transaction_date_time', lastMonthDate)
          .order('transaction_date_time', { ascending: true })
        if (error || IncomeError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          const { totalIncomeAmount, incomeTransactionsDateAmountData } = this.getIncomeComputations(incomeResponse)
          const { expensesTotalAmount, expensesTransactionsDateAmountData } = this.getExpensesComputations(expenseResponse)
          const totalExpenseIncome = totalIncomeAmount + expensesTotalAmount
          const percentageIncomeAmount = Math.round((totalIncomeAmount / totalExpenseIncome) * 100)
          const percentageExpenseAmount = Math.round((expensesTotalAmount / totalExpenseIncome) * 100)
          this.incomeBarChartOptions = {
            xaxis: {
              categories: this.currentMonthDates,
            },
          }

          this.expensesBarChartOptions = {
            xaxis: {
              categories: this.currentMonthDates,
            },
          }

          this.incomeBarChartseries[0].data = incomeTransactionsDateAmountData
          this.expensesBarChartseries[0].data = expensesTransactionsDateAmountData
          this.apexChatData = {
            series: [percentageIncomeAmount, percentageExpenseAmount],
          }
        }
      } catch (error) {
        this.$Progress.fail()
      } finally {
        this.$Progress.finish()
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
