<template>
  <b-row class="match-height">
    <b-col
      sm="12"
      md="7"
      class="mb-2"
    >
      <b-card
        v-if="data"
        class="card-app-design h-100"
      >
        <b-badge variant="primary">
          {{ data.currentDate }}
        </b-badge>
        <!-- <b-card-text class="font-small-2 mb-2">
      {{ data.subtitle }}
    </b-card-text> -->

        <!-- design group -->
        <!-- <div class="design-group">
      <h6 class="section-label">
        Team
      </h6>
      <b-badge
        v-for="team in data.teams"
        :key="team.color"
        :variant="team.color"
        class="mr-1"
      >
        {{ team.name }}
      </b-badge>
    </div>
    <div class="design-group">
      <h6 class="section-label">
        Members
      </h6>
      <b-avatar
        v-for="(member,index) in data.members"
        :key="index"
        :variant="member.color"
        :text="member.text"
        :src="member.img"
        size="34"
        class="mr-1"
      />
    </div> -->
        <!--/ design group -->

        <div class="design-planning-wrapper justify-content-center mt-3">
          <div
            class="design-planning px-3 py-1"
          >
            <p class="text-body text-secondary h4 mb-1">
              LiveStock
            </p>
            <div class="d-flex justify-content-between bg-secondary bg-lighten-2 rounded px-1 py-1">
              <p
                class="h6 mb-0 mr-2"
                style="margin-top: 5px"
              >
                Available Quantity
              </p>
              <h5
                v-if="data"
                class="bg-primary mb-0 rounded"
                style="padding: 5px"
              >
                {{ formatNumber(data.totalAvailableLiveStock) }}
              </h5>
            </div>

          </div>
        </div>

        <div class="design-planning-wrapper justify-content-center">
          <div
            class="design-planning px-3 py-1"
          >
            <p class="text-body text-secondary h4 mb-1">
              Poultry
            </p>
            <div class="d-flex justify-content-between bg-secondary bg-lighten-2 rounded px-1 py-1">
              <p
                class="h6 mb-0 mr-2"
                style="margin-top: 5px"
              >
                Available Quantity
              </p>
              <h5
                v-if="data"
                class="bg-primary mb-0 rounded"
                style="padding: 5px"
              >
                {{ formatNumber(data.totalAvailablePoultry) }}
              </h5>
            </div>

          </div>
        </div>

        <!-- button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          block
          to="/manage-livestock-and-poultry"
        >
          View Details
        </b-button>
      </b-card>
    </b-col>
    <b-col
      sm="12"
      md="5"
      class="mb-2"
    >
      <b-card
        class="card-app-design"
      >
        <b-card-title class="">
          <span class="d-flex justify-content-center text-info">
            Livestock
          </span>
        </b-card-title>
        <div
          v-for="(item, index) in data.livestockCategory"
          :key="index"
          class="livestock-data row px-1"
        >
          <div class="w-50 text-secondary">
            {{ item }}
          </div>
          <div class="d-flex w-50 justify-content-between">
            <div class="text-secondary">
              -
            </div>
            <h5
              class="mb-0"
              style="padding: 5px; color: #c4d8ee"
            >
              {{ formatNumber(getLivestockCategoryQuantity(item)) }}
            </h5>
          </div>
          <hr class="w-100">
        </div>
      </b-card>

      <b-card
        class="card-app-design"
      >
        <b-card-title class="">
          <span class="d-flex justify-content-center text-info">
            Poultry
          </span>
        </b-card-title>
        <div
          v-for="(item, index) in data.poultryCategory"
          :key="index"
          class="poultry-data row px-1"
        >
          <div class="w-50 text-secondary">
            {{ item }}
          </div>
          <div class="d-flex w-50 justify-content-between">
            <div class="text-secondary">
              -
            </div>
            <h5
              v-if="data"
              class="mb-0"
              style="padding: 5px; color: #c4d8ee"
            >
              {{ formatNumber(getPoultryCategoryQuantity(item)) }}
            </h5>
          </div>
          <hr class="w-100">
        </div>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BBadge, BCardTitle, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getTodayDate, formatNumber } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { supabase } from '@/libs/supabaseClient'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formatNumber,
      data: {
        totalAvailablePoultry: 0,
        totalAvailableLiveStock: 0,
        liveStockResponseData: [],
        poultryResponseData: [],
        groupedPoultryData: [],
        groupedLivestockData: [],
        poultryCategory: [],
        livestockCategory: [],
        currentDate: getTodayDate(),
      },
    }
  },

  created() {
    this.handleGetLivestockAggregatedData()
    // data
  },

  methods: {
    getLivestockCategoryQuantity(index) {
      const categoryData = this.data.groupedLivestockData[index]
      const totalQuantity = categoryData.map(item => item.quantity)
        .reduce((acc, quantity) => acc + quantity, 0)
      return totalQuantity
    },
    getPoultryCategoryQuantity(index) {
      const categoryData = this.data.groupedPoultryData[index]
      const totalQuantity = categoryData.map(item => item.quantity)
        .reduce((acc, quantity) => acc + quantity, 0)
      return totalQuantity
    },
    handleGetPoultryData(responseData) {
      const availableTotal = responseData
        .map(obj => obj.quantity)
        .reduce((acc, quantity) => acc + quantity, 0)

      const groupedByCategoryName = responseData.reduce((grouped, currentItem) => {
        // Use the category as the key
        const category = currentItem.name

        // If the category doesn't exist in the grouped object, create an array for it
        if (!grouped[category]) {
          // eslint-disable-next-line no-param-reassign
          grouped[category] = []
        }

        // Add the current item to the array for the corresponding category
        grouped[category].push(currentItem)
        return grouped
      }, [])

      // Use Set to store unique category values
      const uniquePoultryName = new Set(responseData.map(obj => obj.name))

      // Convert Set to an array
      const uniquePoultryNameArray = Array.from(uniquePoultryName)
      return {
        availableTotal,
        groupedPoultryData: groupedByCategoryName,
        poultryCategoryName: uniquePoultryNameArray,
      }
    },
    handleGetLivestockData(responseData) {
      const availableLivestockTotal = responseData
        .map(obj => obj.quantity)
        .reduce((acc, quantity) => acc + quantity, 0)
      const groupedByLivestockCategoryName = responseData.reduce((grouped, currentItem) => {
        // Use the category as the key
        const { category } = currentItem

        // If the category doesn't exist in the grouped object, create an array for it
        if (!grouped[category]) {
          // eslint-disable-next-line no-param-reassign
          grouped[category] = []
        }

        // Add the current item to the array for the corresponding category
        grouped[category].push(currentItem)
        return grouped
      }, [])

      // Use Set to store unique category values
      const uniqueLiveStockName = new Set(responseData.map(obj => obj.category))

      // Convert Set to an array
      const uniqueLiveStockNameArray = Array.from(uniqueLiveStockName)
      return {
        availableLivestockTotal,
        groupedLivestockData: groupedByLivestockCategoryName,
        livestockCategories: uniqueLiveStockNameArray,
      }
    },
    async handleGetLivestockAggregatedData() {
      this.$Progress.start()
      try {
        const { data: liveStockResponse, error } = await supabase
          .from('live_stock_and_poultry')
          .select('*')
          .neq('category', 'Poultry')

        const { data: poultryResponse, error: poultryError } = await supabase
          .from('live_stock_and_poultry')
          .select('*')
          .eq('category', 'Poultry')

        console.log('::debug liveStockResponse:', liveStockResponse)
        console.log('::debug poultryResponse:', poultryResponse)
        if (error || poultryError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          const { availableTotal, groupedPoultryData, poultryCategoryName } = this.handleGetPoultryData(poultryResponse)
          const { availableLivestockTotal, groupedLivestockData, livestockCategories } = this.handleGetLivestockData(liveStockResponse)
          this.data = {
            totalAvailablePoultry: availableTotal,
            totalAvailableLiveStock: availableLivestockTotal,
            liveStockResponseData: liveStockResponse,
            poultryResponseData: poultryResponse,
            groupedPoultryData,
            groupedLivestockData,
            poultryCategory: poultryCategoryName,
            livestockCategory: livestockCategories,
            currentDate: getTodayDate(),
          }
          console.log('::debug Analytic data:', this.data)
        }
      } catch (error) {
        this.$Progress.fail()
      } finally {
        this.$Progress.finish()
      }
    },
  },
}
</script>
