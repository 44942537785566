<template>
  <section id="dashboard-analytics">
    <!-- <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation :data="data.congratulations" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.subscribersGained"
          icon="UsersIcon"
          :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
          statistic-title="Subscribers Gained"
          :chart-data="data.subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="Orders Received"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
    </b-row> -->

    <!-- <b-row class="match-height">
      <b-col lg="6">
        <analytics-avg-sessions :data="data.avgSessions" />
      </b-col>
      <b-col lg="6">
        <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <b-col sm="12">
        <analytics-app-design />
      </b-col>
      <b-col sm="12">
        <produce-card />
      </b-col>
      <b-col sm="12">
        <income-expense-statistics />
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col cols="12">
        <invoice-list />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// import { kFormatter } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
// import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'

import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import ProduceCard from './ProduceCard.vue'
import IncomeExpenseStatistics from './IncomeExpenseStatistics.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsAppDesign,
    IncomeExpenseStatistics,
    ProduceCard,
  },
}
</script>
