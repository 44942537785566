<template>
  <div class="produce-stat">
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-card-title class="d-flex justify-content-center">
            Produce Overview
          </b-card-title>

          <div class="row">
            <b-col
              sm="12"
              md="5"
            >
              <div class="d-flex justify-content-center">
                <b-card class="card-app-design p-0">
                  <div class="design-planning-wrapper justify-content-center mt-2">
                    <div
                      class="design-planning px-3 py-1"
                    >
                      <h1 class="text-info">
                        {{ formatNumber(produceResponse.availableProduceTotal) }}<span class="text-secondary"> Acres</span>
                      </h1>
                      <p>Land coverage for produce planted</p>

                    </div>
                  </div>
                </b-card>
              </div>
            </b-col>

            <b-col
              sm="12"
              md="7"
              style="
                border-left: 1px solid #ccc;
                padding-left: 32px;"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Land Size(Acres)</th>

                    <th>Quantity</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in produceResponse.produceCategories"
                    :key="index"
                  >
                    <td>{{ item }}</td>
                    <td>{{ formatNumber(getProduceAreaSize(item)) }}</td>
                    <td>{{ formatNumber(getProduceCategoryQuantity(item)) }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="mt-3 d-flex justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  block
                  class="w-50"
                  to="/register-produce"
                >
                  View Details
                </b-button>
              </div>
            </b-col>

          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatNumber } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { supabase } from '@/libs/supabaseClient'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      produceResponse: {
        availableProduceTotal: 0,
        groupedProduceData: [],
        produceCategories: [],
      },
      formatNumber,
    }
  },

  created() {
    this.handleGetProduceAggregatedData()
  },

  methods: {
    getProduceCategoryQuantity(index) {
      const categoryData = this.produceResponse.groupedProduceData[index]
      const totalQuantity = categoryData.map(item => item.quantity_sown)
        .reduce((acc, quantity) => acc + quantity, 0)
      return `${totalQuantity + categoryData[0].quantity_unit}`
    },
    getProduceAreaSize(index) {
      const categoryData = this.produceResponse.groupedProduceData[index]
      const totalQuantity = categoryData.map(item => item.land_size)
        // eslint-disable-next-line radix
        .reduce((acc, quantity) => parseInt(acc) + parseInt(quantity), 0)
      return totalQuantity
    },
    handleGetProduceData(responseData) {
      const availableProduceTotal = responseData
        .map(obj => obj.land_size)
        // eslint-disable-next-line radix
        .reduce((acc, quantity) => parseInt(acc) + parseInt(quantity), 0)
      const groupedByProduceCategoryName = responseData.reduce((grouped, currentItem) => {
        // Use the category as the key
        const category = currentItem.config_produce.name

        // If the category doesn't exist in the grouped object, create an array for it
        if (!grouped[category]) {
          // eslint-disable-next-line no-param-reassign
          grouped[category] = []
        }

        // Add the current item to the array for the corresponding category
        grouped[category].push(currentItem)
        return grouped
      }, [])

      // Use Set to store unique category values
      const uniqueProduceName = new Set(responseData.map(obj => obj.config_produce.name))

      // Convert Set to an array
      const uniqueProduceNameArray = Array.from(uniqueProduceName)
      return {
        availableProduceTotal,
        groupedProduceData: groupedByProduceCategoryName,
        produceCategories: uniqueProduceNameArray,
      }
    },
    async handleGetProduceAggregatedData() {
      this.$Progress.start()
      try {
        const { data: produceResponse, error } = await supabase
          .from('produce')
          .select(`
            *,
            config_produce (
              id, name, code, description
            )
          `)
          .neq('status', 'Harvesting')
          .neq('status', 'Post Harvest')
          .neq('status', 'Storage')
          .neq('status', 'Sold')
        if (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          console.log('::debug produceResponse:', produceResponse)
          const { availableProduceTotal, groupedProduceData, produceCategories } = this.handleGetProduceData(produceResponse)
          console.log('::debug Produce data:', availableProduceTotal)
          console.log('::debug Produce groupedProduceData:', groupedProduceData)
          console.log('::debug Produce produceCategories:', produceCategories)
          this.produceResponse = {
            availableProduceTotal,
            groupedProduceData,
            produceCategories,
          }
        }
      } catch (error) {
        this.$Progress.fail()
      } finally {
        this.$Progress.finish()
      }
    },
  },
}
</script>
